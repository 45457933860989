
import { defineComponent, onMounted, ref, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import * as moment from "moment";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "payment-methods",
  props: {
    tfa_seq: String,
  },
  components: {},
  setup(props) {
    const state = reactive({
      myItem: {},
    });

    onMounted(async () => {
      MenuComponent.reinitialization();
      let rTfa = await mAxiosApi.prototype.getAxios("/getHeadFacture/" + props.tfa_seq);
      state.myItem = rTfa.results[0];
    });
    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatMoney = (n) => {
      if (n === undefined) return "0.00";
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
    const myItem = ref({
      tfa_seq: props.tfa_seq,
      tfa_code: "F21SN0908002",
      tfa_date_creation: "08.09.2021",
      tfa_date_echeance: "28.10.2021",
      tfa_libelle_contact: "Monsieur Jean Dupond",
      tfa_libelle_devise: "Dollar ($)",
      tfa_parite: "Parité 1€ = 0.865 $",
      tfa_total_ht: "8'987.00 $",
      tfa_total_ttc: "10'105.14 $",
      tfa_total_reglement: "0.00 $",
      tfa_total_avoir: "0.00 $",
      tfa_solde: "Facture soldée",
      tfa_icon_solde: "media/icons/duotune/general/gen043.svg",
    });

    return {
      myItem,
      state,
      formatDate,
      formatMoney,
    };
  },
});
