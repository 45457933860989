<template>
  <div class="d-flex flex-wrap py-5" v-if="state.myItem.tfa_seq">
    <div class="flex-equal me-5">
      <table class="table table-flush fw-bold gy-1">
        <tbody>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Facture")}}</td>
            <td class="text-gray-800">{{ state.myItem.tfa_code }}</td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Date")}}</td>
            <td class="text-gray-800">
              {{ formatDate(state.myItem.tfa_date_creation) }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Echéance")}}</td>
            <td class="text-gray-800">
              {{ formatDate(state.myItem.tfa_date_echeance) }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Contact")}}</td>
            <td class="text-gray-800">
              {{ state.myItem.con_prenom }}
              {{ state.myItem.con_nom }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Devise")}}</td>
            <td class="text-gray-800">
              {{ state.myItem.dev_libelle }} ({{
                state.myItem.dev_sigle
              }})
              <i>{{ state.myItem.tfa_parite_euro_vt }}</i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex-equal">
      <table class="table table-flush fw-bold gy-1">
        <tbody>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Total HT")}}</td>
            <td class="text-gray-800">
              {{ formatMoney(state.myItem.tfa_total_ht) }}
              {{ state.myItem.dev_sigle }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Total TTC")}}</td>
            <td class="text-gray-800">
              {{ formatMoney(state.myItem.tfa_total_ttc) }}
              {{ state.myItem.dev_sigle }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Total Réglement")}}</td>
            <td class="text-gray-800">
              <a href="#" class="text-gray-900 text-hover-primary"
                >{{ formatMoney(state.myItem.tfa_mnt_reglement) }}
                {{ state.myItem.dev_sigle }}</a
              >
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Total Avoir")}}</td>
            <td class="text-gray-800">
              {{ formatMoney(state.myItem.tfa_mnt_avoir) }}
              {{ state.myItem.dev_sigle }}
            </td>
          </tr>
          <tr>
            <td class="text-muted min-w-125px w-125px">{{$t("Solde")}}</td>
            <td class="text-gray-800">
              <span
                v-if="state.myItem.tfa_solde == 0"
                class="svg-icon svg-icon-2 svg-icon-success"
              >
                <inline-svg src="media/icons/duotune/general/gen043.svg" />
                <span>{{$t("Facture soldée")}}</span>
              </span>
              <span v-else class="svg-icon svg-icon-2 svg-icon-success">
                <span class="text-danger">
                  {{ formatMoney(state.myItem.tfa_solde) }}
                  {{ state.myItem.dev_sigle }}
                </span>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import * as moment from "moment";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "payment-methods",
  props: {
    tfa_seq: String,
  },
  components: {},
  setup(props) {
    const state = reactive({
      myItem: {},
    });

    onMounted(async () => {
      MenuComponent.reinitialization();
      let rTfa = await mAxiosApi.prototype.getAxios("/getHeadFacture/" + props.tfa_seq);
      state.myItem = rTfa.results[0];
    });
    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatMoney = (n) => {
      if (n === undefined) return "0.00";
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
    const myItem = ref({
      tfa_seq: props.tfa_seq,
      tfa_code: "F21SN0908002",
      tfa_date_creation: "08.09.2021",
      tfa_date_echeance: "28.10.2021",
      tfa_libelle_contact: "Monsieur Jean Dupond",
      tfa_libelle_devise: "Dollar ($)",
      tfa_parite: "Parité 1€ = 0.865 $",
      tfa_total_ht: "8'987.00 $",
      tfa_total_ttc: "10'105.14 $",
      tfa_total_reglement: "0.00 $",
      tfa_total_avoir: "0.00 $",
      tfa_solde: "Facture soldée",
      tfa_icon_solde: "media/icons/duotune/general/gen043.svg",
    });

    return {
      myItem,
      state,
      formatDate,
      formatMoney,
    };
  },
});
</script>
