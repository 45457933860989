
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { useStore } from "vuex";
import prochaineEcheance from "@/views/still/board/echeances.vue";
import Activites from "@/views/still/board/activites.vue";
import mAxiosApi from "@/api";
// import CalendarApp1 from "@/components/calendar/CalendarApp1.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    prochaineEcheance,
    Activites,
    // CalendarApp1,
  },
  setup() {
    const state = reactive({
      loaderEnabled: true,
      totOffre: "",
      totCommande: "",
      totFacture: "",
      rSociete: "",
      rAdresse: "",
      soc_logo: "",
      con_prenom: "",
      con_nom: "",
      con_role: "",
    });

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Vue d'ensemble", ["Board StillOnline"]);
      const store = useStore();

      let societe = await mAxiosApi.prototype.getAxios("/getSociete");
      state.rSociete = societe.records[0];

      // let societe = await selectSoc(soc_seq);
      // state.rSociete = societe;
      state.soc_logo = "data:image/jpg;base64," + societe.records[0].soc_logo;

      let adresse = await mAxiosApi.prototype.getAxios("/getAdresse");
      state.rAdresse = adresse.records[0];

      state.con_prenom = store.getters.currentUser.con_prenom;
      state.con_nom = store.getters.currentUser.con_nom;
      state.con_role = store.getters.currentUser.con_role;
      state.loaderEnabled = false;
    });

    return {
      state,
    };
  },
});
