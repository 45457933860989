
import { defineComponent, onMounted, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import * as moment from "moment";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "payment-methods",
  props: {
    tof_seq: String,
  },
  components: {},
  setup(props) {
    const state = reactive({
      myItem: {},
    });

    onMounted(async () => {
      MenuComponent.reinitialization();
      let rTof = await mAxiosApi.prototype.getAxios("/getHeadOffre/" + props.tof_seq );
      state.myItem = rTof.results[0];
    });
    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatMoney = (n) => {
      if (n === undefined) return "0.00";
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
    return {
      state,
      formatDate,
      formatMoney,
    };
  },
});
