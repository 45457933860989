
import { defineComponent, onMounted, ref, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
  },
  setup() {
    const state = reactive({
      list: [],
      loaderEnabled: true,
    });
    onMounted(async () => {
      MenuComponent.reinitialization();
      let rEch = await mAxiosApi.prototype.getAxios("/getMAJ");
      state.list = rEch.results;
      state.loaderEnabled = false;
    });
    const list = ref([
      {
        date: "17.10.2021",
        class: "text-primary",
        description:
          "Une nouvelle facture disponible <b><a href='#'>FS21SN09008</a></b>.",
      },
      {
        date: "15.10.2021",
        class: "text-info",
        description:
          "Délai de livraison mis à jour sur la commande <b><a href='#'>HDF55640</a></b>.",
      },
      {
        date: "13.10.2021",
        class: "text-warning",
        description:
          "Nouveau contrat de support actif <b><a href='#'>ERT-74891</a></b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891-2</a></b> concernant votre projet <b>153156456</b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891</a></b> concernant votre projet <b>153156456</b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891</a></b> concernant votre projet <b>153156456</b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891</a></b> concernant votre projet <b>153156456</b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891</a></b> concernant votre projet <b>153156456</b>.",
      },
    ]);

    return {
      list,
      state,
    };
  },
});
