<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <div class="d-flex flex-column flex-xl-row" v-if="!state.loaderEnabled">
    <!--begin::Sidebar-->
    <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
      <!--begin::Card-->
      <div class="card mb-5 mb-xl-8">
        <!--begin::Card body-->
        <div class="card-body pt-15">
          <!--begin::Summary-->
          <div class="d-flex flex-center flex-column mb-0">
            <!--begin::Avatar-->
            <!--
            <div
              class="symbol mb-7"
              style="width: 150px; height: auto"
              v-if="state.soc_logo.length > 30"
            >
              <img
                :src="state.soc_logo"
                alt="image"
                style="width: 150px; height: auto; background-color: #ececec"
              />
            </div>
            -->
            <!--end::Avatar-->

            <!--begin::Societe-->
            <span
              class="fs-2 text-dark fw-bolder mb-3"
              style="text-align: center"
            >
              {{ state.rSociete.soc_nom_societe }}
            </span>
            <!--end::Societe-->
            <!--begin::Name-->
            <a
              class="fs-3 text-gray-800 text-hover-primary fw-bolder mb-1 mt-1"
            >
              {{ state.con_prenom }} {{ state.con_nom }}
            </a>
            <div class="fs-5 fw-bold text-muted mb-0">{{ state.con_role }}</div>
            <!--end::Position-->
          </div>
          <!--end::Summary-->
          <div class="separator separator-dashed my-3"></div>
          <!--begin::Details content-->
          <div id="kt_customer_view_details" class="collapse show">
            <div class="py-5 fs-6">
              <div class="fw-bolder mt-0">{{$t("CCO ID")}}</div>
              <div class="text-gray-600">
                <span
                  class="text-gray-600 text-hover-primary"
                  v-if="state.rSociete.soc_CCOID !== ''"
                  >{{ state.rSociete.soc_CCOID }}</span
                >
                <span class="text-danger" v-else>{{$t("Non renseigné")}}</span>
              </div>
              <div class="fw-bolder mt-5">{{$t("SMART ACCOUNT")}}</div>
              <div class="text-gray-600">
                <span
                  class="text-gray-600 text-hover-primary"
                  v-if="state.rSociete.soc_smartaccount != ''"
                  >{{ state.rSociete.soc_smartaccount }}</span
                >
                <span
                  class="text-danger"
                  v-if="state.rSociete.soc_smartaccount == ''"
                  >{{$t("Non renseigné")}}</span
                >
              </div>
              <div v-if="state.rAdresse && state.rAdresse.adr_seq">
                <div class="fw-bolder mt-5">{{$t("Addresse principale")}}</div>
                <div class="text-gray-600">
                  <span>{{ state.rAdresse.adr_ligne_1 }}</span>
                  <span v-if="state.rAdresse.adr_ligne_2"
                    ><br />{{ state.rAdresse.adr_ligne_2 }}</span
                  >
                  <span><br />{{ state.rAdresse.adr_cp }}</span>
                  <span>&nbsp;{{ state.rAdresse.adr_ville }}</span>
                  <span>&nbsp;-&nbsp;{{ state.rAdresse.adr_pays }}</span>
                </div>
              </div>
              <div class="separator separator-dashed my-3 mt-5"></div>
              <div class="fw-bolder mt-5" v-if="state.rSociete.emp_civilite == 0">
                {{$t("Votre interlocuteur Stillnetwork")}}
              </div>
              <div class="fw-bolder mt-5" v-else>
                {{$t("Votre interlocutrice Stillnetwork")}}
              </div>
              <div class="text-gray-600">
                <span>
                  <span v-if="state.rSociete.emp_civilite == 0">{{$t("M.")}} </span>
                  <span v-else>{{$t("Mme.")}} </span>
                  {{ state.rSociete.emp_prenom }} {{ state.rSociete.emp_nom }}
                  {{$t("est joignable :")}}
                </span>
              </div>
              <div class="mt-10">
                <a
                  :href="'mailto:' + state.rSociete.emp_mail_pro"
                  class="btn btn-flex btn-light btn-color-primary px-6"
                  style="width: 100%"
                  v-if="state.rSociete.emp_mail_pro"
                >
                  <span class="svg-icon svg-icon-2x">
                    <i class="bi bi-envelope fs-3"></i>
                  </span>
                  <span class="d-flex flex-column align-items-start ms-2">
                    <span class="fs-4 fw-bolder">{{$t("Par mail")}}</span>
                    <span class="fs-7">{{ state.rSociete.emp_mail_pro }}</span>
                  </span>
                </a>
                <a
                  :href="'sip://' + state.rSociete.emp_tel"
                  class="btn btn-flex btn-light btn-color-primary mt-2 px-6"
                  style="width: 100%"
                  v-if="state.rSociete.emp_tel"
                >
                  <span class="svg-icon svg-icon-2x">
                    <i class="bi bi-telephone-outbound fs-3"></i>
                  </span>
                  <span class="d-flex flex-column align-items-start ms-2">
                    <span class="fs-4 fw-bolder">{{$t("Par téléphone")}}</span>
                    <span class="fs-7">{{ state.rSociete.emp_tel }}</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <!--end::Details content-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <div class="flex-lg-row-fluid ms-lg-5">
      <div
        class="tab-pane fade show active"
        id="kt_customer_view_overview_tab"
        role="tabpanel"
      >
        <!--begin::Row-->
        <div class="row g-5 g-xl-12">
          <div class="col-xl-12">
            <prochaineEcheance card-classes="mb-0"></prochaineEcheance>
          </div>
          <div class="col-xl-12">
            <Activites widget-classes="mb-12"></Activites>
          </div>
        </div>
        <!--end::Row-->
        <!--
        <div class="row g-5 g-xl-12">
          <div class="col-xxl-12">
            <CalendarApp1></CalendarApp1>
          </div>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { useStore } from "vuex";
import prochaineEcheance from "@/views/still/board/echeances.vue";
import Activites from "@/views/still/board/activites.vue";
import mAxiosApi from "@/api";
// import CalendarApp1 from "@/components/calendar/CalendarApp1.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    prochaineEcheance,
    Activites,
    // CalendarApp1,
  },
  setup() {
    const state = reactive({
      loaderEnabled: true,
      totOffre: "",
      totCommande: "",
      totFacture: "",
      rSociete: "",
      rAdresse: "",
      soc_logo: "",
      con_prenom: "",
      con_nom: "",
      con_role: "",
    });

    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Vue d'ensemble", ["Board StillOnline"]);
      const store = useStore();

      let societe = await mAxiosApi.prototype.getAxios("/getSociete");
      state.rSociete = societe.records[0];

      // let societe = await selectSoc(soc_seq);
      // state.rSociete = societe;
      state.soc_logo = "data:image/jpg;base64," + societe.records[0].soc_logo;

      let adresse = await mAxiosApi.prototype.getAxios("/getAdresse");
      state.rAdresse = adresse.records[0];

      state.con_prenom = store.getters.currentUser.con_prenom;
      state.con_nom = store.getters.currentUser.con_nom;
      state.con_role = store.getters.currentUser.con_role;
      state.loaderEnabled = false;
    });

    return {
      state,
    };
  },
});
</script>
