<template>
  <transition name="fade">
    <div :class="`card pt-4 ${cardClasses}`" v-if="!state.loaderEnabled">
      <div class="card-header border-0">
        <div class="card-title">
          <h3 class="fw-bolder mb-0">{{$t("Prochaines échéances")}}</h3>
        </div>
      </div>
      <div id="kt_method" class="card-body pt-0">
        <div
          v-if="state.list.length == 0"
          class="
            alert
            bg-light-primary
            alert-primary
            d-flex
            align-items-center
            p-5
            mb-10
          "
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">{{$t("Aucune échéance")}}</h4>
            <span>{{$t("Il n'existe pour le moment aucune échéance à suivre.")}}</span>
          </div>
        </div>
        <div
          class="py-0"
          data-kt-customer-payment-method="row"
          v-for="(item, index) in state.list"
          :key="index"
        >
          <div class="py-3 d-flex flex-stack flex-wrap">
            <div
              class="d-flex align-items-center collapsible rotate collapsed"
              data-bs-toggle="collapse"
              :href="`#kt_method_${item.id}`"
              role="button"
              aria-expanded="false"
              :aria-controls="`#kt_method_${item.id}`"
            >
              <div class="me-3 rotate-90">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="media/icons/duotune/arrows/arr071.svg" />
                </span>
              </div>

              <span
                class="svg-icon svg-icon-1 svg-icon-3x"
                :class="`svg-icon-${item.class}`"
              >
                <inline-svg :src="item.icon" />
              </span>

              <div class="me-3 ms-2">
                <div class="d-flex align-items-center">
                  <div
                    class="badge ms-0 text-gray-800"
                    :class="`badge-light-${item.class}`"
                  >
                    {{ capitalize($t(item.type)) }}
                  </div>
                  <div class="text-gray-800 fw-bolder ms-5">
                    {{ item.title }}
                  </div>
                </div>
                <div class="text-muted ms-3" v-html="translateDescription(item.description)"></div>
              </div>
            </div>
            <div class="d-flex my-3 ms-9">
              <a
                @click="routeEcheance(item.href, item.type)"
                class="btn btn-icon btn-active-light-dark w-30px h-30px me-1"
              >
                <span
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  :title="$t(item.tooltip)"
                >
                  <span class="svg-icon svg-icon-3 svg-icon-2x svg-icon-dark">
                    <inline-svg src="media/icons/duotune/general/gen004.svg" />
                  </span>
                </span>
              </a>
            </div>
          </div>
          <div
            :id="`kt_method_${item.id}`"
            class="fs-6 ps-10 collapse"
            data-bs-parent="#kt_method"
            style=""
          >
            <headerFacture
              v-if="item.type == 'Facture'"
              :tfa_seq="item.seq"
            ></headerFacture>
            <headerOffre
              v-if="item.type == 'Offre commerciale'"
              :tof_seq="item.seq"
            ></headerOffre>
            <headerContrat
              v-if="item.type == 'Contrat de support'"
              :cnt_seq="item.seq"
            ></headerContrat>
          </div>
          <div class="separator separator-dashed"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import headerFacture from "@/views/still/board/headerFacture.vue";
import headerOffre from "@/views/still/board/headerOffre.vue";
import headerContrat from "@/views/still/board/headerContrat.vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "payment-methods",
  props: {
    cardClasses: String,
  },
  components: {
    headerFacture,
    headerOffre,
    headerContrat,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      list: [],
      loaderEnabled: true,
    });
    const { t } = useI18n()

    const capitalize = (val) => {
      return val.toUpperCase();
    };

    const translateDescription = (description) => {
      let translatable = ""

      if (description.indexOf("Total dû depuis le") !== -1) {
        translatable = "Total dû depuis le"
      }
      if (description.indexOf("Date d'échéance au") !== -1) {
        translatable = "Date d'échéance au"
      }
      if (description.indexOf("Offre valide jusqu'au") !== -1) {
        translatable = "Offre valide jusqu'au"
      }
      if (description.indexOf("Contrat arrivant à échéance le") !== -1) {
        translatable = "Contrat arrivant à échéance le"
      }

      return translatable !== "" ? t(translatable) + description.split(translatable)[1] : description
    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      let rEch = await mAxiosApi.prototype.getAxios("/getEcheances");
      state.list = rEch.results;
      state.loaderEnabled = false;
    });
    const routeEcheance = (href, type) => {
      router.push({ name: type, params: { code: href } });
    };
    return {
      state,
      routeEcheance,
      capitalize,
      translateDescription,
    };
  },
});
</script>
